/* eslint-disable react/no-array-index-key */
import React from 'react'

// Components
import { Paragraph } from 'components/StyledComponents'
import CustomLink from 'components/CustomLink'

// Third Party
import styled from 'styled-components'

const SocialsWrapper = styled.div``

const Social = styled.div``

const StyledCustomLink = styled(CustomLink)``

const Socials = ({ data, className }) => (
  <SocialsWrapper className={`${className ? `${className}` : ``}`}>
    <Paragraph className="font-weight-xl font-size-18 pb-2">Volg ons op</Paragraph>
    <div className="d-flex">
      {data.pageContact.socialscontact.map((social, index) => (
        <Social key={index} className="pr-3">
          <StyledCustomLink newPage external to={social.link.url}>
            <img src={social.icon.localFile.publicURL} alt="" height="30" />
          </StyledCustomLink>
        </Social>
      ))}
    </div>
  </SocialsWrapper>
)

export default Socials