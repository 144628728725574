/* eslint-disable react/no-array-index-key */
import React from 'react'

// Components
import { Paragraph } from 'components/StyledComponents'
import CustomLink from 'components/CustomLink'

// Third Party
import styled from 'styled-components'

const StyledCustomLink = styled(CustomLink)`
  :hover {
    color: ${(props) => props.theme.color.text.striking};
  }
`

const CommunicationWrapper = styled.div``

const CommunItem = styled.div``

const Communication = ({ data, className }) => {

  const numberSanitize = number => {
    const san = `+31${number.slice('1').replace(/ /g, '')}`
    return san
  }
  return (
    <CommunicationWrapper className={`${className ? `${className}` : ``}`}>
      {data.pageContact.informationcontact.map((info, index) => (
        <CommunItem key={index} className="d-flex pb-3">
          <div className="d-flex align-items-start pt-1 pr-3">
            <img src={info.icon.localFile.publicURL} alt="" width="21" />
          </div>
          <div>
            <Paragraph className="text-uppercase font-weight-xl">{info.label}</Paragraph>
            {info.label === 'E-mail' && (
              <StyledCustomLink external newPage to={`mailto:${info.value}`}>{info.value}</StyledCustomLink>
            )}
            {info.label === 'Telefoon' && (
              <StyledCustomLink external newPage to={`tel:${info.value}`}>{info.value}</StyledCustomLink>
            )}
            {info.label === 'Whatsapp' && (
              <StyledCustomLink external newPage to={`https://api.whatsapp.com/send?phone=${numberSanitize(info.value)}&text=Ik had een vraag over een dienst op uw pagina`}>{info.value}</StyledCustomLink>
            )}
          </div>
        </CommunItem>
      ))}
    </CommunicationWrapper>
  )
}

export default Communication