import React from 'react'
import { graphql } from 'gatsby'
import Plaatjie from '@ubo/plaatjie'

// Components
import SEO from 'components/SEO'
import Layout from 'components/Layout'
import CustomBreadCrumb from 'components/Common/Breadcrumb'
import Communication from 'components/Contact/Communication'
import GravityForm from 'components/GravityForm'
import Kantoren from 'components/Contact/Kantoren'
import Socials from 'components/Contact/Socials'

// Third Party
import styled from 'styled-components'

const Contact = styled.div``

const ContactContainer = styled.div``

const Separator = styled.div`
  height: 1px;
  width: 70%;

  @media (max-width: 991px) {
    width: 100%;
  }
`

const StyledImg = styled(Plaatjie)`
  height: 500px;

  @media (max-width: 991px) {
    height: 350px;
  }
`

const ContactTemplate = ({ data: { page }, pageContext }) => (
  <Layout>
    {/* {() => ( */}
      <>
        <SEO seo={page.seo} />
        <Contact>
          <CustomBreadCrumb
            data={page}
            className="py-2"
            pageContext={pageContext}
          />
          <div className="pb-0 pb-md-2">
            <StyledImg
              loading="eager"
              image={page.pageContact.bannercontact}
              alt=""
            />
          </div>
          <div className="container">
            <div className="row pt-lg-5 pb-5">
              <div className="col-12 col-lg-7 pt-lg-0 pt-5 pl-lg-3">
                <h2 className="font-weight-bold font-size-ms mb-4">Contact</h2>
                <Communication data={page} />
                <Separator className="color-background-main mt-4 mb-5" />
                <Kantoren data={page} />
                <Socials data={page} />
              </div>
              <div className="col-12 col-lg-5 pt-lg-0 pt-5 pr-lg-3">
                <ContactContainer className="color-background-secondary d-flex justify-content-lg-end justify-content-center p-4 p-sm-5">
                  <GravityForm redButton id={1} />
                </ContactContainer>
              </div>
            </div>
          </div>
        </Contact>
      </>
    {/* )} */}
  </Layout>
)

export default ContactTemplate

export const pageQuery = graphql`
  query ($databaseId: Int!) {
    page: wpPage(databaseId: { eq: $databaseId }) {
      title
      id
      databaseId
      pageContact {
        bannercontact {
          localFile {
            childImageSharp {
              gatsbyImageData(quality: 100, width: 2000)
            }
          }
        }
        informationcontact {
          label
          value
          icon {
            localFile {
              publicURL
            }
          }
        }
        socialscontact {
          link {
            title
            url
          }
          icon {
            localFile {
              publicURL
            }
          }
        }
        officescontact {
          name
          address
          zipcodeCity
          routeLink {
            url
          }
          icon {
            localFile {
              publicURL
            }
          }
        }
      }
      seo {
        canonical
        cornerstone
        focuskw
        metaDesc
        metaKeywords
        metaRobotsNofollow
        metaRobotsNoindex
        opengraphAuthor
        opengraphDescription
        opengraphModifiedTime
        opengraphPublisher
        opengraphPublishedTime
        opengraphSiteName
        opengraphTitle
        opengraphType
        opengraphUrl
        title
        twitterDescription
        twitterTitle
        opengraphImage {
          sourceUrl
        }
        twitterImage {
          sourceUrl
        }
      }
    }
  }
`
