/* eslint-disable react/no-array-index-key */
import React from 'react'

// Components
import { Paragraph } from 'components/StyledComponents'
import CustomLink from 'components/CustomLink'

// Third Party
import styled from 'styled-components'

const StyledCustomLink = styled(CustomLink)``

const KantorenWrapper = styled.div``

const Kantoor = styled.div`

`

const Kantoren = ({ data, className }) => (
  <KantorenWrapper className={`d-flex flex-wrap justify-content-sm-start ${className ? `${className}` : ``}`}>
    {data.pageContact.officescontact.map((office, index) => (
      <Kantoor className="d-flex pb-5 pr-5" key={index}>
        <div className="pr-3">
          <img src={office.icon.localFile.publicURL} alt="" width="15" />
        </div>
        <div>
          <Paragraph className="text-uppercase font-weight-xl font-size-18">{office.name}</Paragraph>
          <Paragraph>{office.address}</Paragraph>
          <Paragraph>{office.zipcodeCity}</Paragraph>
          <StyledCustomLink external newPage to={office.routeLink.url}>
            <Paragraph className="color-text-striking">&gt; Routebeschrijving</Paragraph>
          </StyledCustomLink>
        </div>

      </Kantoor>
    ))}
  </KantorenWrapper>
)

export default Kantoren